import FullName from "./components/FullName/FullName";
import Description from "./components/Description/Description";
import ProfileImage from "./components/ProfileImage/ProfileImage";
import SocialLinks from "./components/SocialLinks/SocialLinks";
import DotGrid from "./components/DotGrid/DotGrid";

import Tilt from "react-parallax-tilt";

const App = () => {
    return (
        <div className="hero-container">
            <main className="hero">
                <Tilt trackOnWindow={true} perspective={4000} className="full-name-tilt" gyroscope={true}>
                    <FullName />
                </Tilt>



                <Tilt trackOnWindow={true} perspective={6000} className="profile-image-tilt" gyroscope={true}>
                    <ProfileImage />
                </Tilt>

                <Tilt trackOnWindow={true} perspective={2000} className="dot-grid-1-tilt" gyroscope={true}>
                    <div className="dot-grid-1">
                        <DotGrid gridWidth={8} gridHeight={8} />
                    </div>
                </Tilt>

                <Tilt trackOnWindow={true} perspective={2000} className="dot-grid-2-tilt" gyroscope={true}>
                    <div className="dot-grid-2">
                        <DotGrid gridWidth={12} gridHeight={12} />
                    </div>
                </Tilt>

                <Tilt trackOnWindow={true} perspective={4000} gyroscope={true}>
                    <Description
                        descriptionText={
                            "full-stack web developer, app researcher, blogger"
                        }
                    />
                    <SocialLinks />
                </Tilt>
            </main>
        </div>
    );
};

export default App;
